export { default as labels } from "./labels";
export { default as rules } from "./rules";
export { default as getStructure } from "./structureFields";
export * from "./schema";

/**
 * build initial values from schema
 *
 * @param presets
 * @param formSchema
 * @param data
 * @returns {{}}
 */
export function buildInitialValues(presets, formSchema, data) {
  let result = {};

  Object.keys(presets).forEach((field) => {
    if (data[presets[field]]) {
      if (field == "pf_nascimento") {
        result[field] = data[presets[field]].substring(0, 10);
      } else {
        result[field] = data[presets[field]];
      }

      if (formSchema[field] && formSchema[field].type == "select") {
        formSchema[field].options.forEach((op) => {
          let v = typeof op == "string" ? op : op.value.toString();
          if (v == result[field]) result[field] = op;
        });
      }
    }
  });

  return result;
}

export function getFormValuesChanged(presets, initialValues, values) {
  let payload = {};

  Object.keys(presets).forEach((field) => {
    if (values[field] === initialValues[field]) {
      return;
    }
    if (typeof values[field] === "object")
      payload[presets[field]] = values[field] ? values[field].value : null;
    else payload[presets[field]] = values[field];
  });

  return payload;
}
