import * as yup from "yup";
import services from "@/shared/http";
import messages from "./custom-messages-test";
import { useValidationsBR } from "validations-br";
import moment from "moment";
import store from "@/store";
import i18n from "@/i18n/wrappers/i18n";
import axios from "axios";
const t = i18n.global.t;
import { dataHelper } from "../helpers/dataHelper";
yup.addMethod(yup.string, "checkPassword", function (errorMessage) {
  let testPasswordInProgress = null;
  let testable = {};

  return this.test(`test-password-check`, errorMessage, function (value) {
    if (Object.prototype.hasOwnProperty.call(testable, value))
      return testable[value];
    if (testPasswordInProgress) return testPasswordInProgress;
    if (!value) return null;
    const { path, createError } = this;
    testPasswordInProgress = services.account
      .validatePassword({
        senha: value,
      })
      .then(() => true)
      .catch(() => createError({ path, message: messages.passwordIncorrect }))
      .finally(() => (testPasswordInProgress = null));
    testable[value] = testPasswordInProgress;
    return testPasswordInProgress;
  });
});

yup.addMethod(yup.string, "emailExists", function (errorMessage) {
  let testEmailInProgress = null;
  let testable = {};

  return this.test(`test-email-exists`, errorMessage, function (value) {
    if (Object.prototype.hasOwnProperty.call(testable, value))
      return testable[value];
    if (testEmailInProgress) return testEmailInProgress;
    if (!value) return true;
    if (!useValidationsBR("email", value)) return null;
    const { path, createError } = this;
    testEmailInProgress = services.validations
      .email(null, {
        partnerSlug: store.getters["partner/getPartnerSlug"],
        email: value,
      })
      .then(() => true)
      .catch(() => createError({ path, message: messages.emailAlreadyExists }))
      .finally(() => (testEmailInProgress = null));
    testable[value] = testEmailInProgress;
    return testEmailInProgress;
  });
});

yup.addMethod(yup.string, "slug", function (errorMessage) {
  let testStoreSlugInProgress = null;
  let testable = {};

  return this.test(`test-store-slug`, errorMessage, function (value) {
    if (Object.prototype.hasOwnProperty.call(testable, value))
      return testable[value];
    if (testStoreSlugInProgress) return testStoreSlugInProgress;
    if (!value) return null;
    const { path, createError } = this;

    if (
      value.match(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/) ||
      /\s/g.test(value) ||
      !/^[a-zA-Z-1-9]*$/.test(value)
    ) {
      return (testable[value] = createError({
        path,
        message: messages.onlyLetters,
      }));
    }

    testStoreSlugInProgress = services.validations
      .slug(null, {
        partnerSlug: store.getters["partner/getPartnerSlug"],
        newSlug: value,
      })
      .then(() => true)
      .catch(() => createError({ path, message: messages.storeSlugInvalid }))
      .finally(() => (testStoreSlugInProgress = null));
    testable[value] = testStoreSlugInProgress;
    return testStoreSlugInProgress;
  });
});

yup.addMethod(yup.string, "phone", function (errorMessage) {
  let testPhoneInProgress = null;
  let testable = {};

  return this.test(`test-phone-check`, errorMessage, function (value) {
    if (!value) return true;
    const { path, createError } = this;
    let result = useValidationsBR("phone", value);

    if (result) {
      if (Object.prototype.hasOwnProperty.call(testable, value)) {
        return testable[value];
      }
      if (testPhoneInProgress) {
        return testPhoneInProgress;
      }

      testPhoneInProgress = services.validations
        .phone(null, {
          partnerSlug: store.getters["partner/getPartnerSlug"],
          phone: value,
        })
        .then(() => true)
        .catch(({ errors }) =>
          createError({
            path,
            message: errors ? errors.phone[0] : messages.phoneIncorrect,
          })
        )
        .finally(() => (testPhoneInProgress = null));
      testable[value] = testPhoneInProgress;

      return testPhoneInProgress;
    } else {
      // alert(messages.phoneIncorrect);
      return createError({ path, message: messages.phoneIncorrect });
    }
  });
});

yup.addMethod(yup.string, "celular", function (errorMessage) {
  let testPhoneInProgress = null;
  let testable = {};

  return this.test(`test-celular-check`, errorMessage, function (value) {
    if (!value) return true;

    if (value.length < 15) return true;

    const { path, createError } = this;
    // let result = useValidationsBR("phone", value);
    // if (result) {
    if (Object.prototype.hasOwnProperty.call(testable, value)) {
      return testable[value];
    }
    if (testPhoneInProgress) {
      return testPhoneInProgress;
    }

    testPhoneInProgress = services.validations
      .cel(null, {
        partnerSlug: store.getters["partner/getPartnerSlug"],
        phone: value,
      })
      .then(() => true)
      .catch(({ errors }) => {
        return createError({
          path,
          message: errors ? errors.celular[0] : "Celular inválido",
        });
      })
      .finally(() => (testPhoneInProgress = null));

    testable[value] = testPhoneInProgress;

    return testPhoneInProgress;
    /* } else {
      return createError({ path, message: messages.phoneIncorrect });
    }*/
  });
});

yup.addMethod(yup.string, "testPhone", function (errorMessage) {
  return this.test(`test-phone-format-check`, errorMessage, function (value) {
    if (!value) return true;
    const { path, createError } = this;
    let result = useValidationsBR("phone", value);
    if (result) {
      return true;
    } else {
      return createError({ path, message: messages.phoneIncorrect });
    }
  });
});

yup.addMethod(yup.string, "testCel", function (errorMessage) {
  return this.test(`test-phone-format-check`, errorMessage, function (value) {
    if (!value) return true;
    const { path, createError } = this;
    let result = useValidationsBR("phone", value);
    if (result) {
      return true;
    } else {
      return createError({ path, message: messages.phoneIncorrect });
    }
  });
});

yup.addMethod(yup.string, "testCPF", function (errorMessage) {
  return this.test(`test-cpf-format-check`, errorMessage, function (value) {
    if (!value) return true;
    const { path, createError } = this;
    let result = useValidationsBR("cpf", value);
    if (result) return true;
    else return createError({ path, message: messages.cpfIncorrect });
  });
});

yup.addMethod(yup.string, "testCNPJ", function (errorMessage) {
  return this.test(`test-cnpj-format-check`, errorMessage, function (value) {
    if (!value) return true;
    const { path, createError } = this;
    let result = useValidationsBR("cnpj", value);
    if (result) {
      return true;
    } else {
      return createError({ path, message: messages.cnpjIncorrect });
    }
  });
});

yup.addMethod(yup.string, "cpf", function (errorMessage) {
  let testCpfInProgress = null;
  let testable = {};

  return this.test(`test-cpf-check`, errorMessage, function (value) {
    if (!value) return true;
    const { path, createError } = this;

    let result = useValidationsBR("cpf", value);

    if (result) {
      if (Object.prototype.hasOwnProperty.call(testable, value)) {
        return testable[value];
      }
      if (testCpfInProgress) {
        return testCpfInProgress;
      }

      testCpfInProgress = services.validations
        .cpf(null, {
          partnerSlug: store.getters["partner/getPartnerSlug"],
          cpf: value.replace(/[^0-9]/g, ""),
        })
        .then(() => {
          localStorage.setItem(
            "validCPFToSignup",
            JSON.stringify({ value: true })
          );
          return true;
        })
        .catch(({ errors }) => {
          localStorage.setItem(
            "validCPFToSignup",
            JSON.stringify({ value: false })
          );
          return createError({
            path,
            message: errors ? errors.cpf[0] : messages.cpfIncorrect,
          });
        })
        .finally(() => (testCpfInProgress = null));

      testable[value] = testCpfInProgress;

      return testCpfInProgress;
    } else {
      localStorage.setItem(
        "validCPFToSignup",
        JSON.stringify({ value: false })
      );

      const errorMessage =
        value.length == 14 ? messages.cpfIncorrect : "Verificando...";
      return createError({ path, message: errorMessage });
    }
  });
});

yup.addMethod(yup.string, "cnpj", function (errorMessage) {
  let testCnpjInProgress = null;
  let testable = {};

  return this.test(`test-cnpj-check`, errorMessage, function (value) {
    if (!value) return true;
    const { path, createError } = this;
    let result = useValidationsBR("cnpj", value);
    if (result) {
      if (Object.prototype.hasOwnProperty.call(testable, value)) {
        return testable[value];
      }
      if (testCnpjInProgress) {
        return testCnpjInProgress;
      }

      testCnpjInProgress = services.validations
        .cnpj(null, {
          partnerSlug: store.getters["partner/getPartnerSlug"],
          cnpj: value.replace(/[^0-9]/g, ""),
        })
        .then(() => true)
        .catch(({ errors }) =>
          createError({
            path,
            message: errors ? errors.cnpj[0] : messages.cnpjIncorrect,
          })
        )
        .finally(() => (testCnpjInProgress = null));
      testable[value] = testCnpjInProgress;

      return testCnpjInProgress;
    } else {
      console.log("cnpj", value.length);
      const errorMessage =
        value.length == 18 ? messages.cnpjIncorrect : "Verificando...";
      return createError({ path, message: errorMessage });
    }
  });
});

yup.addMethod(yup.string, "minAge", function (errorMessage) {
  return this.test(`test-age-check`, errorMessage, function (value) {
    if (!value) return true;
    const { path, createError } = this;

    return (
      moment().diff(moment(value), "years") >= 18 ||
      createError({ path, message: "teste" })
    );
  });
});

yup.addMethod(yup.string, "maxAge", function (errorMessage) {
  return this.test(`test-age-check`, errorMessage, function (value) {
    if (!value) return true;
    const { path, createError } = this;

    return (
      moment().diff(moment(value), "years") <= 100 ||
      createError({ path, message: messages.maxAge })
    );
  });
});

/**
 * VALIDAÇÕES PARA PIX
 */
yup.addMethod(yup.string, "testCNPJPix", function (errorMessage) {
  let testInProgress = null;

  return this.test(`test-cnpj-format-check`, errorMessage, function (value) {
    if (!value) return true;
    const { path, createError } = this;
    let result = useValidationsBR("cnpj", value);

    if (result) {
      testInProgress = fetchValidationField(value, path, createError);

      return testInProgress;
    } else return createError({ path, message: messages.cnpjIncorrect });
  });
});

yup.addMethod(yup.string, "testCPFPix", function (errorMessage) {
  let testInProgress = null;

  return this.test(`test-cpf-format-check`, errorMessage, function (value) {
    if (!value) return true;
    const { path, createError } = this;
    let result = useValidationsBR("cpf", value);

    if (result) {
      testInProgress = fetchValidationField(value, path, createError);

      return testInProgress;
    } else return createError({ path, message: messages.cpfIncorrect });
  });
});

yup.addMethod(yup.string, "testPhonePix", function (errorMessage) {
  let testInProgress = null;

  return this.test(`test-phone-format-check`, errorMessage, function (value) {
    if (!value) return true;
    const { path, createError } = this;
    let result = useValidationsBR("phone", value);

    if (result) {
      testInProgress = fetchValidationField(
        value,
        path,
        createError,
        "testPhonePix"
      );
      return testInProgress;
    }
  });
});

yup.addMethod(yup.string, "testEmailPix", function (errorMessage) {
  let testInProgress = null;

  return this.test(`test-email-pix-check`, errorMessage, function (value) {
    if (!value) return true;
    const { path, createError } = this;
    let result = useValidationsBR("email", value);

    if (result) {
      testInProgress = fetchValidationField(value, path, createError);
      return testInProgress;
    } else return createError({ path, message: messages.emailIncorrect });
  });
});

yup.addMethod(yup.string, "testRadomKey", function (errorMessage) {
  let testInProgress = null;

  return this.test(`test-radom-key-check`, errorMessage, function (value) {
    if (!value) return true;
    const { path, createError } = this;

    testInProgress = fetchValidationField(value, path, createError);

    return testInProgress;
  });
});

/**
 * @param value
 * @param path
 * @param createError
 * @param type
 * @returns
 */
export const fetchValidationField = (value, path, createError, type) => {
  return services.account
    .validatePIX({
      key:
        type == "testPhonePix" ? `+55${value.replace(/[^0-9]/g, "")}` : value,
      taxId: localStorage.getItem("partnerCPFOrCNPJ").replace(/[^0-9]/g, ""),
    })
    .then((data) => {
      if (data.status) return true;
      else
        return createError({
          path,
          message: t("validations.key_pix_dont_match", {
            value: localStorage.getItem("partnerCPFOrCNPJ"),
          }),
        });
    })
    .catch(() =>
      createError({ path, message: t("validations.error_key_pix") })
    );
};

const fetchIdValidation = ({ idExterno }, { cpf }) => {
  let conf = {
    url:
      store.getters["auth/getSignUpSkeleton"].id_validation.endpoint +
      "/" +
      idExterno +
      "/" +
      cpf,
    method: "GET",
  };

  return axios(conf);
};

yup.addMethod(yup.string, "testIdExterno", function (errorMessage) {
  let testInProgress = null;
  let testable = {};

  return this.test(`test-id-externo-check`, errorMessage, function (value) {
    const { path, createError } = this;

    if (!value) {
      dataHelper.helperText("");
      return true;
    }

    if (Object.prototype.hasOwnProperty.call(testable, value)) {
      return testable[value];
    }

    if (value.length) {
      const cpfToSignUp = localStorage.getItem("signUpCPF");

      if (!cpfToSignUp) {
        dataHelper.helperText("");

        return createError({
          path,
          message: "Digite um CPF válido em 'CPF do contato principal'",
        });
      }

      testInProgress = fetchIdValidation(
        { idExterno: value },
        {
          cpf: JSON.parse(cpfToSignUp)
            .document_cpf_contact.replaceAll(".", "")
            .replaceAll("-", ""),
        }
      )
        .then((data) => {
          dataHelper.helperText(data.data.help ? data.data.help : "");
          if (data.status) return true;
        })
        .catch((data) => {
          dataHelper.helperText(
            data.response.data.errors.external_id[1]
              ? data.response.data.errors.external_id[1]
              : ""
          );

          return createError({
            path,
            message: data.response.data.errors.external_id[0],
          });
        })
        .finally(() => {
          testInProgress = null;
        });
    } else {
      dataHelper.helperText("");
      return createError({ path, message: "Verificando..." });
    }
    testable[value] = testInProgress;
    return testInProgress;
  });
});

export default yup;
